












import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import { useI18n } from 'vue-composable'

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    shape: {
      type: String,
      default: 'rectangle'
    },
    useAll: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const { $t } = useI18n()

    const state = reactive({
      currentIndex: 0,
      getItems: computed(() => {
        const allTab = props.useAll ? $t('Global.all.all.blank').value : ''
        return [allTab, ...props.items].filter(Boolean)
      })
    })

    const changeTab = (index: number) => {
      if (state.currentIndex === index) return
      state.currentIndex = index
      emit('change', index)
    }

    store.commit('initBaseTabValue')

    return {
      ...toRefs(state),
      changeTab
    }
  }
})
