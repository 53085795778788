
















import { defineComponent } from '@vue/composition-api'
import store from '@/store'
import { useI18n } from 'vue-composable'
import { toReadableNumber } from '@/helpers'

export default defineComponent({
  props: {
    card: {
      type: Object,
      default: () => ({})
    },
    remainingCoupons: {
      type: Number
    }
  },
  setup (props) {
    const { $t } = useI18n()

    const {
      dispatch
    } = store

    const getCoupon = () => {
      dispatch('coupon/GET_COUPON', props.card)
    }

    return {
      $t,
      getCoupon,
      toReadableNumber
    }
  }
})
