














import { defineComponent, computed, PropType, reactive, toRefs } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import CouponItem from '@/components/CouponItem.vue'

import type { ICoupon, IMyCoupon } from '@/types'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    CouponItem
  },
  props: {
    filteredCards: {
      type: Array as PropType<ICoupon[] | IMyCoupon[]>,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const { $route } = router.app
    const {
      getters
    } = store

    const state = reactive({
      currentTabIndex: 0,
      sourceCurrencyId: computed(() => $route.query.currency || getters['country/$currentSourceCurrencyId'])
    }) as State

    const flipCard = (clickedIndex: number) => {
      emit('flipCard', clickedIndex)
    }

    return {
      ...toRefs(state),
      flipCard
    }
  }
})
