








































import { computed, defineComponent, reactive, ref, toRefs, watch } from '@vue/composition-api'
import store from '@/store'
import { useI18n, useOnOutsidePress } from 'vue-composable'
import { createDimEl, focusInput, isInApp } from '@/helpers'

interface State {
  [key: string]: any;
  input: HTMLInputElement | null;
}

export default defineComponent({
  setup () {
    const { $t } = useI18n()

    const {
      state: {
        modal,
        util
      },
      commit,
      dispatch
    } = store

    const popupEl = ref(null)

    const state = reactive({
      $t,
      input: null,
      isAndroid: computed(() => isInApp && /Android/i.test(window.navigator.userAgent)),
      openedCouponNumberPopup: computed(() => modal.visibles.includes('inputCouponNumber')),
      form: {
        coupon: ''
      },
      errorMessage: computed(() => util.inputMessage)
    }) as State

    const resetValidation = () => {
      commit('$SET_STATE', { path: 'util.inputMessage', value: '' })
    }

    const inputCouponNumber = () => {
      const isOpened = state.openedCouponNumberPopup
      if (isOpened) return commit('modal/$CLOSE_MODAL')
      commit('modal/$OPEN_MODAL', { modalName: 'inputCouponNumber', showBackdropInApp: false })
    }

    const onSubmit = (event: Event) => {
      event.preventDefault()
      if (!state.form.coupon) return commit('$SET_STATE', { path: 'util.inputMessage', value: $t('Global.error.input.none').value })
      resetValidation()
      dispatch('coupon/GET_COUPON', { code: state.form.coupon })
    }

    watch(
      () => state.openedCouponNumberPopup,
      () => {
        focusInput(state.openedCouponNumberPopup, state.input)
        resetValidation()
        createDimEl(state.openedCouponNumberPopup)
      }
    )

    useOnOutsidePress(popupEl, () => {
      state.openedCouponNumberPopup && inputCouponNumber()
    })

    return {
      ...toRefs(state),
      popupEl,
      inputCouponNumber,
      onSubmit
    }
  }
})
