




































































































import { defineComponent, PropType, reactive, ref, computed, toRefs } from '@vue/composition-api'
import { sendToApp, toReadableNumber } from '@/helpers'
import MyCouponCardFooter from '@/components/MyCouponCardFooter.vue'
import CouponBookCardFooter from '@/components/CouponBookCardFooter.vue'
import couponItem from '@/mixins/couponItem.ts'
import store from '@/store'
import { useI18n, useOnScroll } from 'vue-composable'

import type { ICoupon, IMyCoupon } from '@/types'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    MyCouponCardFooter,
    CouponBookCardFooter
  },
  props: {
    card: {
      type: Object as PropType<ICoupon | IMyCoupon>,
      default: () => ({})
    },
    index: {
      type: Number
    }
  },
  setup (props, { emit }) {
    const { $t } = useI18n()

    const {
      currentTabIndex,
      getCard,
      isMyCoupon,
      labels,
      remainingCoupons,
      remainingTimer,
      couponBenefitLabel
    } = couponItem(props)

    const {
      state: {
        coupon
      }
    } = store

    const scrollBody = ref(null)

    const { scrollTop } = useOnScroll(scrollBody)

    const state = reactive({
      $t,
      eventDetailParams: (url: string) => {
        const title = $t('Global.label.event.blank').value
        const isSelfEvent = !/^(((http(s?)):\/\/))/.test(url)
        if (!isSelfEvent) return { baseUri: url, title }
        const path = `/${url}`
        return { baseUri: process.env.VUE_APP_SENTBE_DOMAIN, path, title }
      },
      isDisabledMyCoupon: computed(() => {
        return isMyCoupon && coupon.currentTabIndex.status
      }),
      sendToApp
    }) as State

    const flipCard = (clickedIndex: number) => {
      emit('flipCard', clickedIndex)
    }

    return {
      ...toRefs(state),
      currentTabIndex,
      getCard,
      isMyCoupon,
      labels,
      remainingCoupons,
      remainingTimer,
      scrollBody,
      scrollTop,
      flipCard,
      toReadableNumber,
      couponBenefitLabel
    }
  }
})
