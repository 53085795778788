





















import { computed, defineComponent, onUnmounted, PropType, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import { sendToApp, remainingTimes } from '@/helpers'
import { useI18n } from 'vue-composable'

import type { ICoupon } from '@/types'

interface State {
  [key: string]: any;
}

export default defineComponent({
  props: {
    card: {
      type: Object as PropType<ICoupon>,
      default: () => ({})
    },
    id: {
      type: String
    },
    expirationDate: {
      type: [Date, String] as PropType<Date | string>,
      default: new Date()
    }
  },
  setup (props) {
    const { $t } = useI18n()

    const {
      state: {
        coupon
      },
      getters,
      dispatch
    } = store

    const [available, completed, expired] = [0, 1, 2]

    const labels = computed(() => {
      return {
        button: {
          [available]: $t('Benefit.button.GoTransfer.none').value,
          [completed]: $t('Benefit.button.UseCoupon.none').value,
          [expired]: $t('Benefit.button.UseCoupon.none').value
        },
        status: {
          [available]: state.remainingTimer,
          [completed]: $t('Benefit.tab.Coupons.Used').value,
          [expired]: props.card.usage_restrictions.use_count <= props.card.used_count
            ? $t('Benefit.guide.NumberExceeded.none').value
            : $t('Benefit.tab.Coupons.Expired').value
        }
      }
    })

    const state = reactive({
      $t,
      labels,
      currentTabIndex: computed(() => {
        return {
          status: coupon.currentTabIndex.status,
          type: coupon.currentTabIndex.type
        }
      }),
      toReadableTimes: getters['util/toReadableTimes'],
      remainingTimeLabel: '',
      remainingTimer: computed(() => state.remainingTimeLabel || state.toReadableTimes(props.expirationDate)),
      isRemainingTimerWithinDay: computed(() => {
        const { days } = remainingTimes(props.expirationDate)
        return !days
      })
    }) as State

    const getCoupon = () => {
      dispatch('coupon/GET_COUPON', props.card)
    }

    const useCoupon = async () => {
      const isDiscounting = !state.currentTabIndex.type
      if (isDiscounting && getters['auth/isVerifyCompleteStatus']) return sendToApp('transfers')
      const { card, id } = props
      dispatch('coupon/USE_COUPON', { card, id })
    }

    let interval: any = null

    const runTimer = () => {
      interval = setInterval(() => {
        const { days, hours, min, sec } = remainingTimes(props.expirationDate)
        const isTimedOutCoupon = [days, hours, min, sec].every(time => !time)
        state.remainingTimeLabel = state.toReadableTimes(props.expirationDate)
        if (isTimedOutCoupon) {
          clearInterval(interval)
        }
      }, 1000)
    }

    state.isRemainingTimerWithinDay && runTimer()

    onUnmounted(() => {
      clearInterval(interval)
    })

    return {
      ...toRefs(state),
      getCoupon,
      useCoupon
    }
  }
})
